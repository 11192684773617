import {
    SEARCH_SAP_GET_DATA,
  } from "./Constants";
  
  const initialState = {
    loading: false,
    error: null,
    searchsap: {},
  };

  const Reducer = (state = initialState, action) => {
    switch (action.type) {     
      //Get
      case SEARCH_SAP_GET_DATA.SEARCH_SAP_GET_DATA_LOAD:
        return {
          ...state,
          loading: true,
          searchsap: {},
          error: null,
        };
      case SEARCH_SAP_GET_DATA.SEARCH_SAP_GET_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          searchsap: action.searchsap,
          error: null,
        };
      case SEARCH_SAP_GET_DATA.SEARCH_SAP_GET_DATA_FAIL:
        return {
          ...state,
          loading: false,
          searchsap: {},
          error: action.error,
        };
      
      default:
        return {
          ...state,
        };
    }
  };
  
  export default Reducer;