import React from "react";
import { Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const RegionalSupport = ({info}) => {

  console.log("i", info)
  
  return (
    <>
      <Row>
        <Col md={8}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Title</th>
                <th>Name</th>
                <th>Work Phone</th>
                <th>Personal Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
            {info.map((data) =>
              <tr>
                <td>{data.title}</td>
                <td>{data.first_name} {data.last_name}</td>                
                <td> <a href= {data?.work_phone ? `tel:${data.work_phone.replace(/[^\d]/g, '')}` : undefined}>  {data?.work_phone} </a> </td>
                <td>{data.personal_phone}</td>
                <td> <a href= {`mailto:${data.email_work}`}>  {data.email_work} </a></td>                
              </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default RegionalSupport;
