import { takeLatest, put, call } from "redux-saga/effects";
import {
  SEARCH_SAP_GET_DATA,
  SEARCH_SAP_GET_DETAIL_API,
} from "./Constants";
import {
    setSearchSAPGetDataSuccess,
    setSearchSAPGetDataFail
} from "./Actions";
import {
  getDetailRequest,
} from "../../../shared/components/Service";


function* getSearchSAPDataService({ id }) {
  try {
    const getSearchSAPDataResponse = yield call(
      getDetailRequest,
      SEARCH_SAP_GET_DETAIL_API + id
    );
    const status = getSearchSAPDataResponse.status;
    if (status === 200) {
      const newData = getSearchSAPDataResponse.data;
      yield put(setSearchSAPGetDataSuccess(newData));
    } else {
      yield put(setSearchSAPGetDataFail(getSearchSAPDataResponse.response.data));
    }
  } catch (error) {
    yield put(setSearchSAPGetDataFail(error));
  }
}


export function* watchSearchSAP() {
  yield takeLatest(
    SEARCH_SAP_GET_DATA.SEARCH_SAP_GET_DATA_LOAD,
    getSearchSAPDataService
  );
}
