import { all, fork } from 'redux-saga/effects'
import { watchCompany } from '../containers/company/redux/Saga'
import { watchDivision } from '../containers/division/redux/Saga'
import { watchRegion } from '../containers/region/redux/Saga'
import { watchMarket } from '../containers/market/redux/Saga'
import { watchAcquisition } from '../containers/acquisition/redux/Saga'
import { watchDensity } from '../containers/density/redux/Saga'
import { watchClassification } from '../containers/store-classification/redux/Saga'
import { watchCompGroup } from '../containers/commission-comp-group/redux/Saga'
import { watchChannelType } from '../containers/storeChannelType/redux/Saga'
import { watchStore } from '../containers/stores/redux/Saga'
import { watchDashboard } from '../containers/home/redux/Saga'
import { watchStoreHours } from '../containers/store-hours/redux/Saga'
import { watchDepartment } from '../containers/employee_departments/redux/Saga'
import { watchLeaveType } from '../containers/employee_leave_type/redux/Saga'
import { watchEmployeeType } from '../containers/employee_type/redux/Saga'
import { watchJobTitles } from '../containers/jobtitles/redux/Saga'
import { watchEmployeeStatus } from '../containers/employee_status/redux/Saga'
import { watchEmployee } from '../containers/employees/redux/Saga'
import { watchHireStatus } from '../containers/hire-status/redux/Saga'
import { watchTermReason } from '../containers/term-reason/redux/Saga'
import { watchFullPartTime } from '../containers/full_part_time_status/redux/Saga'
import { watchHourlySalary } from '../containers/hourly_salary_status/redux/Saga'
import { watchQueueTable } from '../containers/queue_table/redux/Saga'
import { watchQuaterly } from '../containers/quarterlyReview/redux/Saga'
import { watchLogin } from '../containers/login/redux/Saga'
import { watchRoles } from '../containers/roles/redux/Saga'
import { watchGroups } from '../containers/groups/redux/Saga'
import { watchModule } from '../containers/module_meta_data/redux/Saga'
import { watchRoleModules } from '../containers/role_module_mapping/redux/Saga'
import { watchEmployeeRoles } from '../containers/employee_role_mapping/redux/Saga'
import { watchOdooStoreTransferTrackers } from '../containers/odoo_reference/OdooStoreTransferTracker/redux/Saga'
import { watchOdooDesignationTrackers } from '../containers/odoo_reference/OdooDesignationTracker/redux/Saga'
import { watchKeyTag } from '../containers/tagging/redux/Saga'
import { watchAudit } from '../containers/auditLog/redux/Saga'
import { watchHireTermQueue } from '../containers/hireTermQueue/redux/Saga'
import { watchGroupJobTitles } from '../containers/group_jobtitles/redux/Saga'
import { watchTShirt } from '../containers/salesUsers/redux/Saga'
import { watchProductTables } from '../containers/productUI/productTables/redux/Saga'
import { watchProducts } from '../containers/productUI/products/redux/Saga'
import { watchRFVisit } from '../containers/retail_foundation/redux/Saga'
import { watchSearchSAP } from '../containers/search-sap/redux/Saga'
import { watchDynamicForm } from '../containers/dynamic_form/redux/Saga'
import { watchMyPlan } from '../containers/my_plan/redux/Saga'

export function* RootSaga() {
  yield all([
    fork(watchCompany),
    fork(watchDivision),
    fork(watchRegion),
    fork(watchMarket),
    fork(watchAcquisition),
    fork(watchDensity),
    fork(watchClassification),
    fork(watchCompGroup),
    fork(watchChannelType),
    fork(watchStore),
    fork(watchDashboard),
    fork(watchStoreHours),
    fork(watchDepartment),
    fork(watchLeaveType),
    fork(watchEmployeeType),
    fork(watchJobTitles),
    fork(watchEmployeeStatus),
    fork(watchEmployee),
    fork(watchHireStatus),
    fork(watchTermReason),
    fork(watchFullPartTime),
    fork(watchHourlySalary),
    fork(watchQueueTable),
    fork(watchQuaterly),
    fork(watchLogin),
    fork(watchRoles),
    fork(watchGroups),
    fork(watchModule),
    fork(watchRoleModules),
    fork(watchEmployeeRoles),
    fork(watchOdooStoreTransferTrackers),
    fork(watchOdooDesignationTrackers),
    fork(watchKeyTag),
    fork(watchAudit),
    fork(watchHireTermQueue),
    fork(watchGroupJobTitles),
    fork(watchTShirt),
    fork(watchProductTables),
    fork(watchProducts),
    fork(watchRFVisit),
    fork(watchSearchSAP),
    fork(watchDynamicForm),
    fork(watchMyPlan)
  ])
}
