import { combineReducers } from "redux";
import CompanyReducer from "../containers/company/redux/Reducer";
import DivisionReducer from "../containers/division/redux/Reducer";
import RegionReducer from "../containers/region/redux/Reducer";
import MarketReducer from "../containers/market/redux/Reducer";
import AcquisitionReducer from "../containers/acquisition/redux/Reducer";
import DensityReducer from "../containers/density/redux/Reducer";
import ClassificationReducer from "../containers/store-classification/redux/Reducer";
import CompGroupReducer from "../containers/commission-comp-group/redux/Reducer";
import ChannelTypeReducer from "../containers/storeChannelType/redux/Reducer"
import AlertReducer from "../containers/alert/Reducer";
import StoreReducer from "../containers/stores/redux/Reducer";
import DashboardReducer from "../containers/home/redux/Reducer";
import StoreHoursReducer from "../containers/store-hours/redux/Reducer";
import DepartmentReducer from "../containers/employee_departments/redux/Reducer"
import LeaveTypeReducer from "../containers/employee_leave_type/redux/Reducer"
import EmployeeTypeReducer from "../containers/employee_type/redux/Reducer"
import JobTitleReducer from "../containers/jobtitles/redux/Reducer"
import EmployeeStatusReducer from "../containers/employee_status/redux/Reducer"
import EmployeeReducer from "../containers/employees/redux/Reducer"
import HireStatusReducer from "../containers/hire-status/redux/Reducer";
import TermReasonReducer from "../containers/term-reason/redux/Reducer";
import FullPartTimeReducer from "../containers/full_part_time_status/redux/Reducer";
import HourlySalaryReducer from "../containers/hourly_salary_status/redux/Reducer";
import NextPrevReducer from "../containers/next_prev_redux/Reducer"
import QueueTableReducer from "../containers/queue_table/redux/Reducer"
import QuarterlyReviewReducer from "../containers/quarterlyReview/redux/Reducer";
import LoginReducer from "../containers/login/redux/Reducer";
import RoleReducer from "../containers/roles/redux/Reducer";
import GroupReducer from "../containers/groups/redux/Reducer";
import ModuleReducer from "../containers/module_meta_data/redux/Reducer";
import RoleModuleReducer from "../containers/role_module_mapping/redux/Reducer";
import EmployeeRoleReducer from "../containers/employee_role_mapping/redux/Reducer";
import FilterReducer from "../containers/table_filters_redux/Reducer";
import OdooStoreTransferTrackersReducer from "../containers/odoo_reference/OdooStoreTransferTracker/redux/Reducer";
import OdooDesignationTrackerReducer from "../containers/odoo_reference/OdooDesignationTracker/redux/Reducer";
import KeyTagReducer from "../containers/tagging/redux/Reducer";
import auditReducer from "../containers/auditLog/redux/Reducer"
import hireTermQueueReducer from '../containers/hireTermQueue/redux/Reducer'
import groupJobTitleReducer from '../containers/group_jobtitles/redux/Reducer'
import tShirtReducer from '../containers/salesUsers/redux/Reducer'
import productTables  from "../containers/productUI/productTables/redux/Reducer";
import products from "../containers/productUI/products/redux/Reducer";
import rfVisits from "../containers/retail_foundation/redux/Reducer";
import searchsap from "../containers/search-sap/redux/Reducer";
import dynamicForm from "../containers/dynamic_form/redux/Reducer";
import myPlan from "../containers/my_plan/redux/Reducer";

const RootReducer = combineReducers({
  company: CompanyReducer,
  division: DivisionReducer,
  region: RegionReducer,
  market: MarketReducer,
  acquisition: AcquisitionReducer,
  density: DensityReducer,
  classification: ClassificationReducer,
  compgroup: CompGroupReducer,
  channelType: ChannelTypeReducer,
  alert: AlertReducer,
  store: StoreReducer,
  dashboardCount: DashboardReducer,
  storeHours: StoreHoursReducer,
  department: DepartmentReducer,
  leaveType: LeaveTypeReducer,
  employeeType: EmployeeTypeReducer,
  jobtitle: JobTitleReducer,
  employeeStatus: EmployeeStatusReducer,
  employee: EmployeeReducer,
  hireStatus: HireStatusReducer,
  termReason: TermReasonReducer,
  fullPartTime: FullPartTimeReducer,
  hourlySalary: HourlySalaryReducer,
  nextPrev: NextPrevReducer,
  tableFilter: FilterReducer,
  login: LoginReducer,
  roles: RoleReducer,
  groups: GroupReducer,
  modules: ModuleReducer,
  roleModules: RoleModuleReducer,
  empRoles: EmployeeRoleReducer,
  odooStoreTransferTrackers: OdooStoreTransferTrackersReducer,
  odooDesignationTrackers: OdooDesignationTrackerReducer,
  keyTag: KeyTagReducer,
  queueTable: QueueTableReducer,
  quaterly: QuarterlyReviewReducer,
  audits:auditReducer,
  hireTermQueue:hireTermQueueReducer,
  groupJobtitle: groupJobTitleReducer,
  tShirtReducer: tShirtReducer,
  productTables: productTables,
  products: products,
  rfVisits: rfVisits,
  searchsap: searchsap,
  forms: dynamicForm,
  myPlans: myPlan
});

export default RootReducer;
