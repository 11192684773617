import {FORM_ALL_DATA, FORM_DATA,  FORM_GET_DATA, FORM_GET_DATA_BY_SUB_ID ,FORM_ADD_DATA, FORM_EDIT_DATA, FORM_DELETE_DATA, FORM_IMPORT_DATA} from './Constants'

const initialState = {
    loading: false,
    error: null,
    allforms:[],
    forms: [],
    form: {},
    formsubid:{},
    edit_flag: false, 
    importData:[]
}

const Reducer = (state = initialState, action) => {
    console.log("action in reducer", action)
    switch (action.type) {
        case FORM_ALL_DATA.FORM_ALL_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                allforms: []
            }
        case FORM_ALL_DATA.FORM_ALL_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                allforms: action.forms,
                error: null
            }
        case FORM_ALL_DATA.FORM_ALL_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                forms: []
            }
        
        case FORM_DATA.FORM_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                forms: []
            }
        case FORM_DATA.FORM_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                forms: action.forms,
                error: null
            }
        case FORM_DATA.FORM_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                forms: []
            }
        
        case FORM_GET_DATA.FORM_GET_DATA_LOAD:
            return {
                ...state,
                loading: true,
                form: {},
                error: null
            }
        case FORM_GET_DATA.FORM_GET_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.form,
                error: null
            }
        case FORM_GET_DATA.FORM_GET_DATA_FAIL:
            return {
                ...state,
                loading: false,
                form: {},
                error: action.error
            }    
            
        case FORM_GET_DATA_BY_SUB_ID.FORM_GET_DATA_BY_SUB_ID_LOAD:
            return {
                ...state,
                loading: true,
                formsubid: {},
                error: null
            }
        case FORM_GET_DATA_BY_SUB_ID.FORM_GET_DATA_BY_SUB_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                formsubid: action.form,
                error: null
            }
        case FORM_GET_DATA_BY_SUB_ID.FORM_GET_DATA_BY_SUB_ID_FAIL:
            return {
                ...state,
                loading: false,
                formsubid: {},
                error: action.error
            }  
        
        case FORM_ADD_DATA.FORM_ADD_DATA_LOAD:
            console.log("Its a FORM_EDIT_DATA_LOAD")
            return {
                ...state,
                loading: true,                 
                error: null
            }        
        case FORM_ADD_DATA.FORM_ADD_DATA_SUCCESS:            
            return { ...state,
            loading: false,    
            edit_flag: true,           
            error: null }
        case FORM_ADD_DATA.FORM_ADD_DATA_FAIL:           
            return {
                ...state,
                loading: false,                     
                error: action.error
            } 
        case FORM_EDIT_DATA.FORM_EDIT_DATA_LOAD:            
            return {
                ...state,
                loading: true,   
                edit_flag: false,                                    
                error: null
            }
        case FORM_EDIT_DATA.FORM_EDIT_DATA_SUCCESS:            
            return { ...state,
                    loading: false,    
                    edit_flag: true,           
                    error: null 
            }
        case FORM_EDIT_DATA.FORM_EDIT_DATA_FAIL:           
            return {
                ...state,
                loading: false, 
                edit_flag: false,                     
                error: action.error
            } 
        case FORM_DELETE_DATA.FORM_DELETE_DATA_LOAD:            
            return {
                ...state,
                loading: true,                                  
                error: null
            }
        case FORM_DELETE_DATA.FORM_DELETE_DATA_SUCCESS:            
            return { ...state,
            loading: false,  
            error: null }            
        case FORM_DELETE_DATA.FORM_DELETE_DATA_FAIL:            
            return {
                ...state,
                loading: false,         
                error: action.error
        } 

        case FORM_IMPORT_DATA.FORM_IMPORT_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                importData: []
            }
        case FORM_IMPORT_DATA.FORM_IMPORT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                importData: action.forms,
                error: null
            }
        case FORM_IMPORT_DATA.FORM_IMPORT_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                importData: []
            }
            
        default:
            return {
                ...state
            }
    }
    
}

export default Reducer;