import React, { useEffect, useState } from "react"
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux"
import DynamicFormAdd from './DynamicFormAdd';
import DynamicFormEdit from './DynamicFormEdit';
import Modalpopup from '../../../shared/components/Modalpopup';
import ListView from "../../../shared/components/listview/ListView"
import { loadFormDataRequest, loadFormGetDataRequest, loadFormGetDataBySubIDRequest, loadFormAddDataRequest, loadFormEditDataRequest, loadFormDeleteDataRequest} from "../../../containers/dynamic_form/redux/Actions"
import FormError from "../../../shared/components/FormError"
import { DELETE, CANCEL, DELETE_CONFIRM } from "../../../shared/constants/Constants"
import DefaultButton from "../../../shared/components/DefaultButton"

const DynamicForm = ({ form_id, resource_id}) => {

  const dispatch = useDispatch();  
  let [shouldCloseModal, setShouldCloseModal] = useState(false);
  let { form,formsubid, edit_flag, error, loading } = useSelector((state) => state.forms);
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  let [selectedTracker, setSelectedTracker] = useState({});
  let [showEditModal, setShowEditModal] = useState(false)
  let [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState(false);
  let actionsPermission = true;

  const handleDelete = () => {
    setShowDeleteModal(false);  
    dispatch(loadFormDeleteDataRequest({
      id: [selectedTracker]
    })) 
    setShouldCloseModal(true)
  }

  let req = form_id + '/' + resource_id;
  console.log("req going undefined",req )
  useEffect(() => {
    console.log("loading loadFormGetDataRequest")
      dispatch(loadFormGetDataRequest(req));      
  }, [dispatch, req]);

  console.log("form loading here",form)
  useEffect(() => {
    if("form data check here",form)
    if (form?.columns != undefined) {
      setHeaders(form.columns);
      console.log("form.columns",form.columns)
      let action = {
        display_name: "",
        field: 'actions',
        type: 'actions',
        hide: false
    }
      setHeaders(prevHeaders => [...prevHeaders, action]);
      setData(form.data);
    }
  }, [form]);
  
  let [clickEditFunc, setClickEditFunc] = useState(null);
  let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
  useEffect(() => {
    setClickEditFunc(
        () => (row) => {
          let param = form_id + '/' + resource_id + '/' + row.submission_id
          console.log(row);
          setSelectedTracker(row);          
          setShowEditModal(true);
        })
    setClickDeleteFunc(
        () => (id) => {
            console.log("setClickDeleteFunc", id)
            setSelectedTracker(id);
            setShowDeleteModal(true);
        })
  }, [])
  const closeEditModal = () => {
    setShowEditModal(false);
    setErrorMessage(null);
  }

  const closeAddModal = () => {
    setShowAddModal(false);
    setErrorMessage(null);
  }

  useEffect(() => {
    if (shouldCloseModal && !loading) {
        if (!error) {
            closeAddModal();
            closeEditModal();
            setTimeout(() => {
              console.log("start reloading after edit and delete", req)
              dispatch(loadFormGetDataRequest(req)); 
            }, 1000);
        } else {
            setErrorMessage(error?.msg?.toString());
        }
        setShouldCloseModal(false)
    }
  }, [loading, error, shouldCloseModal])

  const handleEdit = (values) => {
    console.log("Values",values )
    let requestValues = values;
    delete requestValues['actions'];    
    dispatch(loadFormEditDataRequest(requestValues))
    setShouldCloseModal(true);
  }

  const handleAdd = (values) => {
    console.log("Values",values )
    let requestValues = values;
    delete requestValues['actions'];    
    dispatch(loadFormAddDataRequest(requestValues))
    setShouldCloseModal(true);
  }

  return (<>
    <Button style={{ width: 115 }} className="btn btn-secondary default-btn-color float-end py-1" onClick={() => { setShowAddModal(true) }}>Add Form</Button>
    <ListView
            columnNames={headers}
            rowData={data}
            clickEdit={clickEditFunc}
            clickDelete={clickDeleteFunc}
        />
    <Modalpopup show={showAddModal} handleClose={closeAddModal} actionTitle="Create Form">
        <FormError>
            {errorMessage}
        </FormError>
        <DynamicFormAdd cancel={closeAddModal} form_id ={form_id}  headers = {headers }  resource_id = {resource_id} submit={handleAdd}   /> 
    </Modalpopup>
    
    <Modalpopup show={showEditModal} actionTitle="Edit" handleClose={closeEditModal} >
        <DynamicFormEdit form_id ={form_id} data = {selectedTracker } headers = {headers }  
                          submission_id = {selectedTracker?.submission_id} resource_id = {resource_id} 
                          submit={handleEdit} 
                          cancel={closeAddModal} /> 
    </Modalpopup>
    <Modalpopup show={showDeleteModal} actionTitle="Delete Additional Attribute" handleClose={() => { setShowDeleteModal(false) }}>
            {DELETE_CONFIRM}
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={() => { handleDelete(selectedTracker) }}
                />
            </div>
        </Modalpopup>
    </>
  )
}

export default DynamicForm